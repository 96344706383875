import moment from "moment";
import { post, WebService } from "./utils";

export const anular = async (rut: string, fecha: string, id: string) => {
  const url = rut === '219999820013' ?
    'https://infrasistemas.sytes.net:7081/FacturacionElectronicaTest/awsgenerarcontradocumento.aspx' :
    'https://infrasistemas.sytes.net:7081/FacturacionElectronicaMulti/awsgenerarcontradocumento.aspx';

  const fechaEmision = moment().format('YYYY/MM/DD');
  const fechaFormatted = moment(fecha, 'DD/MM/YYYY').format('YYYY/MM/DD');

  const sr = `<?xml version="1.0" encoding="utf-8"?>
    <soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:cfe="cfe.facturacionElectronica.com.uy">
     <soapenv:Header/>
      <soapenv:Body>
        <cfe:WSGenerarContraDocumento.Execute>
            <cfe:Empresaruc>${rut}</cfe:Empresaruc>
            <cfe:Fefechaalta>${fechaFormatted}</cfe:Fefechaalta>
            <cfe:Fecabid>${id}</cfe:Fecabid>
            <cfe:Refrazonref>Anulación</cfe:Refrazonref>
            <cfe:Feiddocfchemis>${fechaEmision}</cfe:Feiddocfchemis>
        </cfe:WSGenerarContraDocumento.Execute>
      </soapenv:Body>
    </soapenv:Envelope>`;

  const anularWS: WebService<string> = {
    url,
    name: 'WSGenerarContraDocumento.Execute',
    // resultSelector: 'WSGenerarContraDocumento.ExecuteResponse',
    resultSelector: 'Error',
    sr,
    transform: (response: string) => response,
  };

//   console.log(anularWS);

//   return;
  return post(anularWS);
};