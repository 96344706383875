import React, { useState, useEffect } from 'react';
import { Table, Empty, DatePicker, Form, Tooltip, Modal } from 'antd';

import { aceptarComprobante, Document, getAllCompras, getAllDocuments, rechazarComprobante } from 'api';
import { ColumnsType } from 'antd/lib/table';
import { TableStyle } from './TableStyle';
import { esPaginationLocale } from 'constants/paginationLocale';
import { buildPDF } from 'api/utils';
import { RootState } from 'store';
import { useSelector, useDispatch } from 'react-redux';
import { datepickerLocale } from 'constants/datepickerLocale';
import moment from 'moment';
import { Store } from 'antd/lib/form/interface';
import { updateDocuments, updateCompras, setDocuments, setCompras, setState } from 'store/docs';

// ** Logos
import iconoAceptado from 'assets/aceptado.png';
import iconoError from 'assets/error.png';
import iconoEspera from 'assets/en espera.png';
import iconoEsperaHaceMucho from 'assets/en espera hace mucho.png';
import iconoRechazado from 'assets/rechazado.png';
import thumb_up from 'assets/thumb_up.png';
import thumb_down from 'assets/thumb_down.png';
import { ContextMenu } from './ContextMenu';
import { anular } from 'api/anulacion';

function getDate(substract: number = 1) {
  let today = new Date();
  today.setDate(today.getDate() - substract);
  let dd = String(today.getDate()).padStart(2, '0');
  let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  let yyyy = today.getFullYear();
  let newD = mm + '/' + dd + '/' + yyyy;
  return new Date(newD).getTime();
}

/*let date_start: any;
let date_end: any;

function getStartDate() {
  return date_start ? date_start : new Date(new Date().getFullYear(), new Date().getMonth(), 1);
}

function getEndDate() {
  return date_end ? date_end : new Date().getTime();
}*/

const { RangePicker } = DatePicker;


const estadoRender = (estado: string, record: Document, type: DocumentType = 'Sales') => {
  if (type === 'Purchases') {
    return estadoRenderPurchases(estado, record);
  }

  return estadoRenderSales(estado, record);
}

const estadoRenderSales = (estado: string, record: Document) => {
  if (estado === 'No aplica') return null;
  const pendingDate = getDate();
  return (<Tooltip title={estado === 'En espera' && record.pdfPayload.fechaGetTime < pendingDate ? 'En espera hace mucho' : estado} >
    <img
      style={{
        width: 20,
        height: 'auto',
        textAlign: 'center'
      }}
      src={estado === 'Aceptado' ? iconoAceptado : estado === 'Rechazado' ? iconoRechazado : estado === 'En espera' ? record.pdfPayload.fechaGetTime < pendingDate ? iconoEsperaHaceMucho : iconoEspera : iconoError} alt="" />
  </Tooltip>)
}

const estadoRenderPurchases = (estado: string, record: Document) => {
  if (estado === 'No aplica') return null;

  const pendingDate = getDate(15);

  return (<Tooltip title={estado === 'Pendiente' && record.pdfPayload.fechaGetTime < pendingDate ? 'Pendiente hace mucho' : estado} >
    <img
      style={{
        width: 20,
        height: 'auto',
        textAlign: 'center'
      }}
      src={estado === 'Aceptado' ? iconoAceptado : estado === 'Rechazado' ? iconoRechazado : estado === 'Pendiente' ? record.pdfPayload.fechaGetTime < pendingDate ? iconoEsperaHaceMucho : iconoEspera : iconoError} alt="" />
  </Tooltip>)
}

type DocumentType = 'Purchases' | 'Sales';

interface DocumentsTableProps {
  documents: Document[];
  type: DocumentType;
}

interface State {
  type: 'Ventas' | 'Compras';
  dates: [moment.Moment, moment.Moment];
}

export const DocumentsTable = ({ documents, type }: DocumentsTableProps) => {

  //const [dates, setDates] = useState([]);
  const [date, setDate] = useState<moment.Moment>(moment(new Date(), 'YYYY-MM'));
  const { rut, impresion, start_new_date, isAdmin } = useSelector((state: RootState) => ({
    rut: state.app.rut,
    impresion: state.preferences.impresion,
    start_new_date: 0,
    isAdmin: state.app.isAdmin
  }));
  const [contextState, setContextState] = useState({
    visible: false,
    position: {
      x: 0,
      y: 0
    },
    record: undefined as Document | undefined
  });

  // ** render button
  const estadoBoton = (estado: string, record: Document) => {
    if (estado !== 'Pendiente') return null;

    // ** Handlers
    const handleUpdate = () => {
      handleDateFinish(date);
    }

    const handleComprobante = async (acepta: boolean) => {
      if (rut !== null) {
        try {
          if (acepta) {
            await aceptarComprobante(rut, record.pdfPayload.docId);
          } else {
            await rechazarComprobante(rut, record.pdfPayload.docId);
          }
        } catch (err) {
          console.error(err)
        } finally {
          handleUpdate();
        }
      }
    }

    return (
      <div style={{
        display: 'flex',
        textAlign: 'center',
        gap: 10
      }}>
        <Tooltip title='Aceptar' >
          <img
            style={{
              width: 20,
              height: 'auto'
            }}
            onClick={(e) => {
              e.stopPropagation();
              handleComprobante(true);
            }}
            src={thumb_up} alt="Aceptar" />
        </Tooltip>
        <Tooltip title='Rechazar' >
          <img
            style={{
              width: 20,
              height: 'auto'
            }}
            onClick={async (e) => {
              e.stopPropagation();
              handleComprobante(false);
            }}
            src={thumb_down} alt="Rechazar" />
        </Tooltip>
      </div>
    )
  }

  // ** Columns
  // TODO add responsive
  const columns = (type: DocumentType): ColumnsType<Document> => [
    {
      title: 'Documento',
      dataIndex: 'cfe',
      key: 'cfe',
    },
    {
      title: 'Serie',
      dataIndex: 'serie',
      key: 'serie',
      width: 15,
    },
    {
      title: 'Número',
      dataIndex: 'numero',
      key: 'numero',
      align: 'right',
      width: 15,
    },
    {
      title: 'Fecha',
      dataIndex: 'fecha',
      key: 'fecha',
    },
    {
      title: type === 'Purchases' ? 'Proveedor' : 'Cliente',
      dataIndex: 'cliente',
      key: 'cliente',
    },
    {
      title: 'Total',
      dataIndex: 'importe',
      key: 'importe',
      align: 'right',
    },
    {
      title: 'Moneda',
      dataIndex: 'moneda',
      key: 'moneda',
    },
    {
      title: 'Tipo',
      dataIndex: 'tipoPago',
      key: 'tipoPago',
    },
    {
      title: type === 'Sales' ? 'DGI' : 'Estado',
      dataIndex: 'estado',
      key: 'estado',
      render: (estado: string, record: Document) => estadoRender(estado, record, type),
      align: 'center'
    },
    {
      title: type === 'Sales' ? 'Empresa' : 'Acción',
      dataIndex: 'estadoEmpresas',
      key: 'estadoEmpresas',
      render: (_, record: Document) => type === 'Sales' ? estadoRender(record.estado, record) : estadoBoton(record.estado, record),
      align: 'center'
    },
  ];

  // console.log(rut)

  /*let docs = useSelector(
    ({ docs }: RootState) => docs
  );*/
  const dispatch = useDispatch();

  // const handleDateFinish = async (date_start: Date, date_end: Date) => {
  const handleDateFinish = async (dateAux: moment.Moment) => {
    const date_start = new Date(new Date(dateAux.format('MM/DD/yyyy')).getFullYear(), new Date(dateAux.format('MM/DD/yyyy')).getMonth(), 1);
    const date_end = new Date(new Date(dateAux.format('MM/DD/yyyy')).getFullYear(), new Date(dateAux.format('MM/DD/yyyy')).getMonth() + 1, 0);

    if (type === 'Sales') {
      const docs = await getAllDocuments(
        rut as string,
        id as number,
        isAccountant,
        moment(date_start).format('DD-MM-yyyy'),
        moment(date_end).format('DD-MM-yyyy')
      );
      dispatch(setDocuments(docs));
    } else if (type === 'Purchases') {
      const comprasdocs = await getAllCompras(
        rut as string,
        id as number,
        isAccountant,
        moment(date_start).format('DD-MM-yyyy'),
        moment(date_end).format('DD-MM-yyyy')
      );
      dispatch(setCompras(comprasdocs));
    }
  }

  const disabledDate = (current: any) => {
    const today = moment();
    if (current.isBefore(today)) {
      return current.isBefore(today.subtract(isAdmin ? 3 : isAccountant ? 1.5 : 1, 'years'));
    }
    return current.isAfter(today.add(1, 'day').startOf('day'));
  };

  const { canExport, id, isAccountant } = useSelector(
    ({ app }: RootState) => app
  );

  // const [{ isLoading }, setState] = useState<{
  //   isLoading: boolean;
  //   error?: boolean;
  // }>({
  //   isLoading: false,
  // });

  const anularComprobante = () => {
    if (rut && contextState?.record?.fecha && contextState?.record?.pdfPayload.docId) {
      const modal = Modal.confirm({
        title: `¿Seguro que desea anular el documento nº ${contextState.record.numero}?`,
        okText: 'Anular',
        cancelText: 'Cancelar',
        async onOk() {
          if (rut && contextState?.record?.fecha && contextState?.record?.pdfPayload.docId) {
            dispatch(setState('LOADING'));
            modal.update({ cancelButtonProps: { disabled: true }})
            try {
              const res = await anular(rut, contextState.record.fecha, contextState.record.pdfPayload.docId);
              // console.log({res})
              if (res === 'false') {
                handleDateFinish(date);
              } else {
                dispatch(setState('READY'));
              }
            } catch (err) {
              console.error(err);
              dispatch(setState('READY'));
            }
            modal.update({ cancelButtonProps: { disabled: false }})
          }
        },
        onCancel() { },
      });
    }
  }

  const menu = <li onClick={anularComprobante}>Anular documento</li>

  function onClickOutside() {
    setContextState(prev => ({ ...prev, visible: false, record: undefined }))
    document.removeEventListener(`click`, onClickOutside)
  }



  return (
    <>
      <TableStyle pointer>
        <Form>
          <Form.Item
            name="dates"
            label="Seleccione el mes:"
            rules={[
              {
              },
              () => ({
                validator(rule, value: any) {

                  if (!value) {
                    return Promise.reject('');
                  }

                  console.log(value)

                  setDate(value)
                  handleDateFinish(value);
                },
              }),
            ]}
          >
            <DatePicker
              defaultValue={moment(new Date(), 'YYYY-MM')}
              picker="month"
              locale={datepickerLocale}
              disabledDate={disabledDate}
              format="MM/YYYY"
              popupStyle={{
                fontSize: "13px",
              }}
            />
          </Form.Item>
        </Form>
        <Table
          locale={{
            emptyText: (
              <Empty
                description="No hay datos"
                image={Empty.PRESENTED_IMAGE_SIMPLE}
              />
            ),
          }}
          pagination={{
            locale: esPaginationLocale,
          }}
          rowClassName={(record: Document) => {
            // ['En espera', 'Error', 'Rechazado'].includes(record.estado)
            let classStr = ''
            if (record.anulado === 'Sí') {
              classStr += 'anulado'
            }

            // if (record.estado === 'Incorrecto' || record.estado === 'Error' || record.estado === 'Rechazado' || (record.pdfPayload.fechaGetTime < getDate() && record.estado === 'En espera')) {
            //   classStr += ' waiting'
            // }

            return classStr
          }}
          rowKey={(record: Document) => `${record.numero}`}
          columns={columns(type)}
          dataSource={documents}
          // onRow={(record) => ({
          //   onClick: () => {
          //     type === 'Purchases'
          //       ? window.open(record.pdf, '_blank')
          //       : window.open(
          //           buildPDF(String(rut), 'A4', record.pdfPayload), // Se fuerza la llamada al API A4
          //           '_blank'
          //         )
          //   },
          //   // window.open(
          //   //   buildPDF(String(rut), impresion, record.pdfPayload),
          //   //   '_blank'
          //   // ),
          //   // onContextMenu: console.log('context'),
          // })}
          onRow={(record) => ({
            onClick: () => type === 'Purchases'
              ? window.open(record.pdf, '_blank')
              : window.open(
                buildPDF(String(rut), 'A4', record.pdfPayload), // Se fuerza la llamada al API A4
                '_blank'
              ),
            onContextMenu: (event) => {
              event.preventDefault();
              // console.log({record})
              // event.stopPropagation();
              if (type === 'Sales' && record.anulado === 'No' && !record.cfe.startsWith('NC')) {
                // console.log('context')
                if (!contextState.visible) {
                  document.addEventListener(`click`, onClickOutside)
                }
                setContextState({
                  visible: true,
                  position: { x: event.clientX, y: event.clientY },
                  record
                })
              } else if (contextState.visible) {
                setContextState(prev => ({ ...prev, visible: false }))
                document.removeEventListener(`click`, onClickOutside)
              }
            }
          })}
        />
      </TableStyle>
      {<ContextMenu {...contextState} menu={menu} />}
    </>
  );
};