import React from "react"


interface ContextMenuProps {
    visible: boolean
    position: { x: number, y: number }
    menu: React.ReactElement<any, string | React.JSXElementConstructor<any>> | (() => React.ReactElement)
}

const ContextMenu: React.FC<ContextMenuProps> = ({ visible, position, menu }) => {
    return (
        <>
            {visible && <ul className="popup" style={{ left: `${position.x}px`, top: `${position.y}px` }}>
                {menu}
            </ul>}
        </>)
}


export {
    ContextMenu
}